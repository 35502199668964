import React from 'react'
import Layout from '../components/layout/Layout'
import AnchorSlug from '../components/AnchorSlug'

import PageData from '../data/privacy-policy-data.yaml'

const PrivacyPolicy = () => (
  <Layout>
    <section id="privacy-policy">
      <div className="container mx-auto p-8 pt-16 text-center">
        <h1 className="text-primary-500 font-semibold tracking-wide uppercase">Company</h1>
        <h1 className="md:text-4xl text-3xl font-semibold tracking-tight justify-center mt-2">{PageData.title}</h1>
        <p className="md:text-lg font-light my-4">{PageData.overview}</p>
        
        <div className="container container mx-auto text-center mt-4">
          {!PageData.sections ? null : 
            PageData.sections.map((section, i) => {
              return (
                <section key={section.id} id={section.id} className={`relative my-0 md:py-8 py-10 md:px-4`}>
                  <AnchorSlug to={section.id} title={section.title} text={section.title}/>
                  <p class="md:text-lg font-light text-center mt-4 space-y-4">
                    {!section.paragraphs ? null : 
                      section.paragraphs.map((paragraph, i) => {
                      return <p className="">{paragraph}</p>
                    })}
                  </p>
                </section>
              )
          })}
        </div>
      
      </div>
    </section>
  </Layout>
)
export default PrivacyPolicy